.app {
    border: none !important;
    padding: 0 16px;
    background-color: #131515;
}

.app .ps-sidebar-container {
    background-color: transparent !important;
}

.ps-menuitem-root {
    margin-bottom: 0px !important;
}

.ps-menu-button {
    /* background-color: #212324 !important; */
    padding: 10px 4px !important;
    margin: 10px 0px;
    border-radius: 4px !important;
}


.ps-menu-button:active {
    background-color: #212324 !important;
}

.ps-menu-button.link {
    flex-direction: row-reverse;
}

.ps-menu-button:hover {
    background-color: #2A2D2E !important;
}

.selected {
    background-color: #2A2D2E !important;
    border-radius: 4px !important;
}

 .ps-submenu-content ul{
     margin-left: 50px;
     background-color: #212324;
     overflow: unset !important;
 }

 .ps-submenu-content li{
    margin: 0px !important;
    position: relative;
 }

.ps-submenu-content li::before, .ps-submenu-content li::after {
    content: '';
    position: absolute;
    border-top: 1px solid #57595B !important;
    width: 1px;
    left: -20px;
}

.ps-submenu-content li::before {
    content: '';
    border-left: 1px solid #57595B;
    bottom: 10px;
    height: 130%;
    top: 0;
    width: 1px;
}

.ps-submenu-content li::after {
    border-top: 1px solid ;
    height: 1px;
    margin-top: 10px;
    background-color: #57595B;
    top: 10px;
    width: 15px;
}

.ps-submenu-content li a {
    display: inline-block;
    padding: 3px 8px;
    text-decoration: none;
}

.ps-submenu-content li:last-child::before {
    height: 30px;
}

.ps-submenu-content li:first-child::after, .ps-submenu-content li:last-child::after {
    border-radius: 0;
}

.ps-menuitem-root.css-1tqrhto:hover {
    background-color: transparent !important;
}


.ps-menuitem-root:hover .ps-menu-label {
    color: white;
}

.ps-menu-label {
    color: #9fa0a1;
    font-size: 14px;
    font-weight: 300;
}

.menu1 {
    padding: 0 !important;
    gap: 10px;
}

.menu1 .ps-menu-button {
    padding: 5px 0 !important;
}

.css-1t8x7v1>.ps-menu-button {
    height: unset !important;
}

.menu1 .ps-menu-button:hover {
    background-color: transparent !important;
}

.menu1 .ps-menu-icon {
    margin-right: 0 !important;
}

.menu1 .ps-menu-icon svg path {
    fill: white;
}

.ps-submenu-content {
    background-color: transparent !important;
}

.css-jn69v9,
.css-honxw6 {
    width: 6px !important;
    height: 6px !important;
    border-right: 1px solid white !important;
    border-bottom: 1px solid white !important;
    margin-right: 5px;
}

.submenu .ps-menu-button {
    padding-left: 35px !important;
}

.css-1tqrhto>.ps-menu-button {
    height: unset !important;
}

.ps-sidebar-container {
    scrollbar-width: thin;
    scrollbar-color: #9fa0a1 #212324;
}

.ps-sidebar-container::-webkit-scrollbar {
    width: 8px;
}

.ps-sidebar-container::-webkit-scrollbar-thumb {
    background-color: #9fa0a1;
    border-radius: 4px;
}

.ps-sidebar-container::-webkit-scrollbar-track {
    background-color: #212324;
}
