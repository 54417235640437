@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Roboto";
    background-color: #131515;
    letter-spacing: 0.6px;
}

.order-num{
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 14rem;
    align-items: center;
    color: transparent;
    -webkit-text-stroke: 4px #2e3133
}

.custom-dropdown .ant-select-selector{
    width: 280px !important;
}

.ant-select-selector{
    position: relative !important;
    width: 170px !important;
    height: 40px !important; 
    text-align: start !important;
    padding-left: 20px !important; 
    padding-right: 8px !important; 
    border-radius: 4px !important;
    background-color: transparent !important;
    color: gray !important;
    border: 1px solid #9fa0a1 !important;
}


.custom-dropdown .ant-select-item {
  color: white; /* Item text color */
}

.custom-dropdown .ant-select-item-option-active {
  background-color: #2e3133; /* Active item background */
}

.custom-dropdown .ant-select-item-option-selected {
  background-color: #2e3133 !important; /* Selected item background */
  color: white !important;
  font-weight: bold; /* Selected item font weight */
}

.custom-dropdown .ant-select-item-option-selected::after {
  content: '✓'; /* Tick mark content */
  color: rgb(33, 118, 167); /* Tick mark color */
  font-size: 16px; /* Tick mark size */
  position: absolute; /* Absolute positioning */
  right: 10px; /* Position from the right */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust vertical positioning */
}

.btnClose:hover{
  background-color:#110d01 !important;
  color: white !important;
  border: solid 1px #DDB951 !important;
}

.btnOk{
  background-color:#ecc653 !important;
  color: white !important;
  border: solid 1px #DDB951 !important;
}

.logo-menu > a{
  background-color: transparent !important;
}

.ant-modal-content{
  background-color: #2e3133 !important;
}

.ant-modal-body > input{
  background-color: #475259 !important;
  color: white !important;
}

.ant-modal-title {
  background-color: #2e3133 !important;
  color: #DDB951 !important;
}

input[type="email"]::placeholder {
    color: white !important; 
    opacity: 1;
}


.custom-datepicker input::placeholder {
    color: white !important; 
    opacity: 1;
  }

  .ant-progress-text{
    color: white !important;
  }

.custom-datepicker {
   padding-left: 10px !important;
}

.custom-datepicker:hover {
    background-color: transparent !important;
    color: gray; 
}

.custom-datepicker:focus {
  background-color: transparent !important;
  color: gray; 
}


.custom-datepicker :focus::placeholder{
  background-color: transparent !important;
  color: gray; 
}


/* Scrollbar width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle (the part that you move) */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #fff;
}
.playlist .ant-select-selector {
    width: 356px !important;
    margin-left: -17px !important;
    margin-top: -10px !important;
    height: 45px !important;
}

.playlist .ant-select {
    border: none !important;
}

.playlist .ant-select-selection-item-content {
    color: white !important;
    font-size: 16px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.playlist .ant-select-selection-item-remove {
    color: white !important;
}

.playlist input[type="search"]::placeholder {
    color: #888 !important;
    opacity: 1 !important;
}

.playlist .ant-select-dropdown {
    background-color: #2e3133 !important;
}

.playlist .ant-select-item {
    color: #fff !important;
}

.playlist .ant-select-dropdown .ant-select-item-option-selected {
    background-color: #d3d3d3 !important;
    color: #000 !important;
}