.mainvisual {
    background-image: url(./assets/images/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.video-detail {
    position: relative;
}

.video-detail::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 250px;
    background: linear-gradient(to bottom, rgba(19, 21, 21, 0.1), #131515);
}

.video-modal::-webkit-scrollbar {
    width: 8px;
}

.video-modal::-webkit-scrollbar-thumb {
    background-color: #9fa0a1;
    border-radius: 4px;
}

.videoUploadDetail::-webkit-scrollbar {
    width: 8px;
}

.videoUploadDetail::-webkit-scrollbar-thumb {
    background-color: #9fa0a1;
    border-radius: 4px;
}


.notification::-webkit-scrollbar {
    width: 8px;
}

.notification::-webkit-scrollbar-thumb {
    background-color: #9fa0a1;
    border-radius: 4px;
}

.search-history::-webkit-scrollbar {
    width: 8px;
}

.search-history::-webkit-scrollbar-thumb {
    background-color: #585a5c;
    border-radius: 4px;
}

.custom-tab {
    color: #9fa0a1 !important;
    font-size: 16px !important;
    font-weight: bold !important;
    text-transform: unset !important;
}

.custom-tab.Mui-selected {
    color: white !important;
}

.MuiBox-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.main-video::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background: linear-gradient(to top, rgba(19, 21, 21, 0.05), #131515);
}

.main-video::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(19, 21, 21, 0.6);
    width: 100%;
    height: 100%;
    z-index: 3;
}

.bg-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 33%;
    background: linear-gradient(to bottom, rgba(19, 21, 21, 0.05), #131515);
}

.mySwiper .swiper-button-prev,
.mySwiper .swiper-button-next {
    width: 32px;
    height: 48px;
    background-color: rgba(19, 21, 21, 0.9);
    border-radius: 4px;
}

.mySwiper .swiper-button-prev::after,
.mySwiper .swiper-button-next::after {
    color: white;
    font-size: 12px;
}

.check-step {
    padding: 0 160px;
    position: relative;
}

.check-step::before {
    content: '';
    position: absolute;
    right: 0;
    width: 180px;
    height: 2px;
    top: 12px;
    background-color: #d8d8d8;
}

.visibility-step {
    padding: 0 160px;
    position: relative;
}

.visibility-step::before {
    content: '';
    position: absolute;
    left: 0;
    width: 180px;
    height: 2px;
    top: 12px;
    background-color: #d8d8d8;
}

.detail-checked::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #453E5C;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.visibility-checked::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #1F2123;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.date-input-container {
    position: relative;
    display: inline-block;
}

.date-input {
    padding: 2px 36px 2px 2px;
}

.date-input::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #9fa0a1;
    transform: translateY(-50%);
    pointer-events: none;
}

.date-input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}


.selectbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    padding-right: 20px;
}

.triangle::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #9fa0a1;
    transform: translateY(-50%);
    pointer-events: none;
}
.arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 6px;
    height: 6px;
    border-right: 1px solid #212324;
    border-bottom: 1px solid #212324;
    transform: translateY(-50%) rotate(45deg);
}

.loginPage {
    position: relative;
    background-image: url(./assets/images/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}